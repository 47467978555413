<template>
  <div class="page-layout__content col-md-6 col-8">

    <div class="section section--shadow-0">

      <div class="section__body">
        <table class="table-3 table-responsive-sm">
          <thead class="table-3__head">
          <tr class="table-3__row">
            <th class="table-3__cell  table-3__hcell">{{ getTranslation('title') }}</th>
            <th class="table-3__cell  table-3__hcell"></th>
          </tr>
          </thead>

          <tbody class="table-3__body">
          <tr v-for="role in levels" v-bind:key="role.id" class="table-3__row">
            <td class="table-3__cell">
              {{ role.name }}
            </td>
            <td class="table-3__cell">
              <router-link :to="{ name: 'auth.levels.update', params: { id: role.id } }" class="float-right">
                {{ getTranslation('edit') }}
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      cardCollapse: true,
      collapseIds: [],
      levels: []
    }
  },
  created() {
    this.setHeadings({
      title: this.getTranslation('levels'),
      breadcrumbs: [
        {
          text: this.getTranslation('levels')
        }
      ]
    });

    this.fetchLevels();
  },
  methods: {
    fetchLevels() {
      this.axios.get(this.$urls.level.levels.url)
          .then(response => {
            this.levels = response.data.data;
          })
    }

  }
}
</script>

<style scoped>

</style>